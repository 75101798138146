@import "../../utils/color/Colors.scss";
.btn.submit-btn {
  width: 160px;
  height: 50px;
  font-family: "gBold";
  font-size: 18px;
  background-color: $master-blue;
  color: #fff;
  &:hover {
    background-color: $master-blue !important;
    color: #fff !important;
  }
  &:disabled {
    background-color: $master-blue !important;
    color: #fff !important;
  }
  &:focus {
    background-color: $master-blue !important;
    color: #fff !important;
  }
  &:active {
    background-color: $master-blue !important;
    color: #fff !important;
  }
}

.input-wrapper {
  position: relative;
  input {
    height: 50px;
    font-size: 20px;
  }
  label {
    font-size: 15px;
  }
}
.alert.alert-danger.custom-error {
  text-align: center !important;
  font-size: 12px !important;
  padding: 4px !important;
  margin: -10px auto !important;
  position: absolute;
  left: 0;
  right: 0;
}

.modal-content {
  margin-top: 150px;
}

.spinner-outer-block {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(0 0 0 / 72%);
  z-index: 999999;
  .spinner-block {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .spinner-border {
    border-right-color: #fff;
  }
}
