@import "../../utils/color/Colors.scss";
.header {
  background-color: $header;
  padding: 20px 100px;
  h1 {
    font-family: "gBold";
    text-align: left;
    color: #fff;
  }
  ul {
    margin: 0;
    padding: 0;
    text-align: right;
    li {
      display: inline-flex;
      margin-left: 20px;
      margin-top: 20px;
      color: #fff;
      a {
        color: #fff;
        font-size: 15px;
        text-decoration: none;
        &:hover {
          color: #fff;
        }
      }
    }
  }
}

@media (min-width: 0) and (max-width: 991px) {
  .header {
    h1 {
      text-align: center;
    }
    ul {
      text-align: center;
    }
  }
}

@media (min-width: 0) and (max-width: 550px) {
  .header {
    padding: 20px 20px;
  }
}
