@import "../../utils/color/Colors.scss";
@import "../../components/common/Common.scss";

.verification {
  padding: 0 100px;
  text-align: left;
  min-height: 150px;
  p {
    color: $font-gray;
    font-size: 17px;
    text-align: center;
  }
  .email-sent {
    p {
      max-width: 700px;
      margin: 0 auto;
      text-align: left;
    }
  }
  .input-wrapper {
    margin-top: 30px;
    .resend-code {
      font-style: italic;
      margin: 5px 0 0 0;
      text-decoration: underline;
      background-color: transparent;
      border: none;
      color: $master-blue;
    }
  }
  .btn.submit-btn {
    margin-top: 54px;
  }
  .submit-page {
    margin: 100px auto 0 !important;
    display: block;
  }
  .email-sent {
    span {
      color: $master-blue;
    }
  }
}

.modal-popup-custom {
  p {
    font-size: 15px;
    color: #404040;
    line-height: 28px;
    span {
      color: $master-blue;
    }
  }

  .modal-footer {
    button {
      min-width: 100px;
      background-color: $master-blue;
      &.cancel {
        opacity: 0.7;
      }
    }
  }
}

@media (min-width: 0) and (max-width: 760px) {
  .verification {
    padding: 0 20px;
  }
}
