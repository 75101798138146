@import "../../utils/color/Colors.scss";
@import "../../components/common/Common.scss";

.privacy-policy {
  padding: 0 100px;
  h2 {
    font-family: "g";
    font-size: 18px;
    text-align: left;
    margin-bottom: 20px;
    span {
      color: $master-blue;
    }
  }
  p {
    text-align: justify;
    font-family: "g";
    color: $font-gray;
    font-size: 15px;
  }
  .checkbox-wrapper {
    padding: 20px 0 0 100px;
    text-align: left;
    span {
      margin-left: 20px;
      font-family: "g";
      font-size: 18px;
    }
    &.last {
      margin-bottom: 50px;
    }
  }
  .submit-btn {
    margin-top: 40px;
    &:disabled {
      background-color: $master-blue;
      border: 0;
      color: #fff;
    }
  }
  .disabled-btn {
    background-color: #b0b0b0;
    width: 160px;
    height: 50px;
    font-family: "gBold";
    font-size: 18px;
    margin-top: 40px;
    color: #fff;
    &:hover {
      background-color: #b0b0b0 !important;
      color: #fff !important;
    }
    &:disabled {
      background-color: #b0b0b0 !important;
      color: #fff !important;
    }
    &:focus {
      background-color: #b0b0b0 !important;
      color: #fff !important;
    }
    &:active {
      background-color: #b0b0b0 !important;
      color: #fff !important;
    }
  }
}
@media (min-width: 0) and (max-width: 760px) {
  .privacy-policy {
    padding: 0 20px;
  }
}
@media (min-width: 0) and (max-width: 550px) {
  .privacy-policy {
    .checkbox-wrapper {
      padding: 20px 0 0 20px;
    }
    .btn {
      width: 120px !important;
    }
  }
}
