@import "../../utils/color/Colors.scss";
.progress-bar {
  ul {
    padding: 0;
    margin: 30px 0 80px 0;
    li {
      display: inline-flex;
      padding: 10px;
      align-items: center;
      .wrapper {
        width: 120px;
        height: 120px;
        background-color: $progress-green;
        border-radius: 100%;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px,
          rgba(0, 0, 0, 0.23) 0px 3px 6px;
        .inner-wrapper {
          background-color: #fff;
          width: 90px;
          height: 90px;
          border-radius: 100%;
          margin: 15px;
          -webkit-box-shadow: inset 0px 0px 4px -2px rgba(0, 0, 0, 0.75);
          -moz-box-shadow: inset 0px 0px 4px -2px rgba(0, 0, 0, 0.75);
          box-shadow: inset 0px 0px 4px -2px rgba(0, 0, 0, 0.75);
          color: #484c4e;
          .number {
            font-family: "gBold";
            font-size: 38px;
          }
          .name {
            font-family: "g";
            font-size: 17px;
          }
        }
      }
      .line {
        height: 5px;
        width: 150px;
        background-color: $progress-green;
        margin-right: 22px;
        border-radius: 3px;
      }
      &.fade {
        opacity: 0.4;
      }
    }
  }
}

@media (min-width: 0) and (max-width: 760px) {
  .progress-bar {
    ul {
      display: inline-flex;
      margin: 30px auto 50px;
      li {
        padding: 0;
        .wrapper {
          width: 80px;
          height: 80px;
          .inner-wrapper {
            width: 70px;
            height: 70px;
            margin: 5px;
            .number {
              font-family: "gBold";
              font-size: 25px;
            }
            .name {
              font-family: "g";
              font-size: 13px;
            }
          }
        }
        .line {
          height: 3px;
          width: 50px;
          margin-right: 0;
        }
      }
    }
  }
}
