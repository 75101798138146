@import "../../utils/color/Colors.scss";
@import "../../components/common/Common.scss";

.details {
  padding: 0 100px 100px;
  text-align: left;
  .title-wrapper {
    margin: 50px 0 20px;
    span.title {
      font-family: "gBold";
      border-bottom: 5px solid $master-blue;
      color: #000;
      font-size: 21px;
      padding-bottom: 5px;
    }
  }
  .input-wrapper {
    margin-top: 30px;
    .form-select {
      height: 50px;
    }
  }
  .bottom-info {
    margin: 30px 0;
    p {
      font-family: "g";
      font-size: 14px;
      line-height: 25px;
      text-align: justify;
      label {
        margin-right: 8px;
      }
    }
  }
  .submit-page {
    margin: 25px auto;
    display: block;
  }
  .set-password {
    width: 100% !important;
    margin-top: 55px !important;
  }
  .avatar-image {
    margin: 0 auto;
    position: relative;
    width: 120px;
    height: 120px;
    border-radius: 100%;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    input[type="file"] {
      color: white;
      background-color: $master-blue;
      font-size: 11px;
      margin: 0 auto;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 5px;
      cursor: pointer;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 10px 20px 20px 20px;
    }
    input[type="file"]::file-selector-button {
      display: none;
    }

    img {
      width: 120px;
      height: 120px;
      border-radius: 100%;
      border: 1px solid #eee;
      object-fit: cover;
    }
  }

  .remaing-time {
    text-align: center;
    letter-spacing: 2px;
    font-size: 20px;
    margin: 0;
    padding: 0;
    font-weight: 800;
    &.green {
      color: #23c123;
    }

    &.orange {
      color: #fc9019;
    }

    &.red {
      color: red;
    }
  }
}

@media (min-width: 0px) and (max-width: 900px) {
  .details {
    padding: 10px;
  }
}
